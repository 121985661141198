<template>
    <div>
        <v-card flat outlined>

            <v-toolbar outlined flat color="grey lighten-4">
                <v-toolbar-title> تغير كلمة المرور </v-toolbar-title>
            </v-toolbar>

            <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
                <v-container>
                    <v-card outlined>
                        <v-container>
                            <v-row>
                                <!-- oldPassword -->
                                <v-col cols="12" md="6">
                                    <h4 class="mb-2"> كلمة المرور القديمة <span class="required">*</span> </h4>
                                    <v-text-field
                                        v-model="item.oldPassword"
                                        placeholder="admin"
                                        color="info"
                                        :rules="[$global.state.required()]"
                                        required
                                        filled
                                        outlined
                                        hide-details="auto"
                                    />
                                </v-col>

                                <v-col cols="12" md="6"></v-col>

                                <!-- password -->
                                <v-col cols="12" md="6">
                                    <h4 class="mb-2"> كلمة المرور الجديدة <span class="required">*</span> </h4>
                                    <v-text-field
                                        v-model="item.password"
                                        outlined filled
                                        color="info" 
                                        placeholder="كلمة المرور الجديدة "
                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="showPassword ? 'text' : 'password'"
                                        @click:append="showPassword = !showPassword">
                                    </v-text-field>
                                </v-col>

                                <!-- confirmPassword -->
                                <v-col cols="12" md="6">
                                    <h4 class="mb-2"> تأكيد كلمة المرور </h4>
                                    <v-text-field
                                        outlined
                                        color="info" filled
                                        type="password"
                                        v-model="item.confirmPassword"
                                        :rules="[$global.state.required(), rules.confirmPassword]"
                                        placeholder="تأكيد كلمة المرور">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                    <!-- actions -->
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn
                                type="submit"
                                :loading="$global.state.loading"
                                x-large
                                color="primary secondary--text"
                                class="btn"
                                :disabled="!valid">
                                حفظ
                            </v-btn>
                            <v-btn
                                @click="close()"
                                x-large
                                color="error"
                                class="mr-2 btn"
                                outlined
                            >إلغاء</v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-form>
        </v-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            item: {},
            valid: false,
            showPassword: false,
            rules: {
                confirmPassword: () => this.item.confirmPassword === this.item.password || "كلمة المرور غير متطابقة",
            },
        };
    },

    methods: {
        reset() {
            this.item = {
                oldPassword: "",
                password: "",
                confirmPassword: "",
            };
        },

        submit() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.changeMyPassword();
            }
        },

       changeMyPassword(){
            this.$global.state.loading = true;
            this.$http
                .post(`${this.$route.meta.endPoint}/changeMyPassword`, this.item)
                .then((res) => {
                    this.$store.dispatch("toastification/setToast", { message: `${res.data.message}`, type: "success" });
                    this.logOut();
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", { message: `${err.data.message}`, type: "error"});
                })
                .finally(() => (this.$global.state.loading = false));
        },

        logOut() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        },
    
        close() {
            this.resetValidation();
            this.reset();
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>
